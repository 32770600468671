.injection-form__form {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.injection-form__label {
  font-size: 3rem;
}

.injection-form__input {
  padding: .5rem;
  font-size: 1.5rem;
}

textarea.injection-form__input {
  min-height: 10rem;
  resize: vertical;
}

.injection-form__button {
  color: #fff;
  cursor: pointer;
  background-color: #000;
  padding: 2rem;
  font-size: 3rem;
}

/*# sourceMappingURL=with-csp-and-trusted-type.358f569a.css.map */

.injection-form__form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.injection-form__label {
  font-size: 3rem;
}

.injection-form__input {
  padding: 0.5rem;
  font-size: 1.5rem;
}

textarea.injection-form__input {
  min-height: 10rem;
  resize: vertical;
}

.injection-form__button {
  padding: 2rem;
  background-color: black;
  color: white;
  font-size: 3rem;
  cursor: pointer;
}
